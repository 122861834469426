<template>
    <div class="action-schedule">
        <template>
            <div class="message">
                <div class="message-top">
                    <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                    <span v-if="isAtype">일정 조율을 요청했습니다</span>
                    <span v-else>약속잡기를 다시 시작합니다!</span>
                </div>
                <div class="message-content">
                    <div v-if="isAtype" class="desc">
                        남성분께서 가능한 일정이 없습니다 😢 메시지를 참고하여 다시 일정을 정해주세요
                    </div>
                    <div v-else class="desc">여성분이 가능한 일정을 다시 보내주셨어요. 일정과 지역을 정해주세요.</div>
                    <div class="grey-box m-b-8">
                        <div class="user-profile">
                            <img :src="photoUrl" alt="" />
                            <div class="profile-info">
                                <div class="age" v-html="age" />
                                <div class="region" v-html="content.region" />
                                <div class="job" v-html="content.job" />
                            </div>
                        </div>
                    </div>
                    <div v-if="isAtype" class="reschedule-message-wrap">
                        <div class="reschedule-title">메세지 내용</div>
                        <div class="reschedule-body">
                            {{ content.reject_message }}
                        </div>
                    </div>
                    <div v-else class="grey-box">
                        <ScheduleProgress :mode="'b'" :selected="1" />
                    </div>
                    <!-- 분기처리 추가 -->
                    <div v-if="userRatePlans.onGoing" class="m-t-8 m-b-16">
                        <!-- <img class="m-r-4" src="@/assets/images/icons/info_pink.png" width="16px" height="16px" />

                        <div class="text" v-html="'약속 확정시 매칭권 1회가 사용됩니다'" /> -->
                    </div>
                </div>
            </div>
            <BottomButton
                v-if="isAtype"
                :disabled="disabled"
                @click="onClickEnterSchedule"
                :label="`일정 다시 입력`"
                :nonFixed="true"
            />
            <BottomButton
                v-else
                :disabled="disabled"
                @click="onClickEnterSchedule"
                :label="`약속잡기 다시 시작`"
                :nonFixed="true"
            />
        </template>
    </div>
</template>

<script>
import ScheduleProgress from './ScheduleProgress'

export default {
    name: 'ActionRescheduleStart',
    props: ['message'],
    components: {
        ScheduleProgress,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return this.$options.filters.asAgeInActionMessage(this.content.birthday)
        },
        isAtype() {
            return this.message.mtype === 'schedule-restart-a'
        },
        disabled() {
            return this.content.status === 1
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
        mannerTickets() {
            return this.$store.getters.mannerTickets.filter(t => t.enabled)
        },
        hasUnpaid() {
            return (this.me || {}).products.some(p => {
                return p.product_id === 62
            })
        },
    },
    methods: {
        onClickEnterSchedule() {
            // if (!this.userRatePlans.onGoing && !this.hasUnpaid) {
            //     this.$modal.basic({
            //         title: '프로그램 이용이 만료되었습니다',
            //         body: '약속을 진행하려면 프로그램을 구매해주세요',
            //         buttons: [
            //             {
            //                 label: 'CONFIRM',
            //                 class: 'btn-primary',
            //             },
            //         ],
            //     })
            // } else {
            if (this.content.manner_like === 'b_user' && !this.isAtype && this.mannerTickets.length === 0) {
                this.$modal
                    .basic({
                        title: '매너 수락',
                        body: '매너 수락 비용 3만원을 결제해주세요. 매너 수락 비용 결제 이후에 매칭권을 사용합니다.',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: '결제하기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            const selectMannerTicket = this.$store.getters.products.ticket_plan.find(
                                item => item.code === `ticket_manner_like`
                            )
                            this.$store.commit('setSelectedProduct', selectMannerTicket)
                            this.$store.commit('setSelectedPlan', selectMannerTicket)
                            this.$router.push({
                                name: 'PaymentMethodsPage',
                                // params: { categoryName: this.category.category },
                            })
                        }
                    })
            } else {
                this.$stackRouter.push({
                    name: 'PromisePage',
                    props: {
                        message: this.message,
                        isAtype: this.isAtype,
                        userInfo: {
                            message: this.message,
                            photoUrl: this.photoUrl,
                            age: this.age,
                            region: this.content.region,
                            job: this.content.job,
                            schedule_id: this.content.schedule_id,
                            date_confirmed: this.content.date_confirmed,
                            meet_place: this.content.meet_place,
                            meet_type_confirmed: this.content.meet_type_confirmed,
                        },
                        reschedule: true,
                    },
                })
            }
            // }
        },
    },
}
</script>

<style scoped lang="scss">
.info {
    background: rgba(255, 61, 107, 0.07);
    border-radius: 8px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #111111;
    display: flex;
    align-items: center;
}
.reschedule-message-wrap {
    background-color: #f7f8f9;
    margin: 8px 0px;
    border-radius: 12px;
    padding: 8px 16px;
    .reschedule-title {
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        margin: 4px 0px;
        color: #94999e;
    }
    .reschedule-body {
        font-size: 14px;
        line-height: 24px;
        color: #111111;
        margin: 4px 0px;
    }
}
</style>
